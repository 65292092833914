<template>
  <van-popup v-model="thankShow" :close-on-click-overlay="false" round>
    <div class="modal">
      <div class="title">
        <span>THANK YOU</span>
      </div>
      <div class="info">
        <span
          v-if="orderData.length && orderData[0].roomNum === 'Parking'"
          class="word-break"
        >
          You can also find your ticket details in the Ticket History.
        </span>
        <span v-else class="word-break"
          >Thank you for your order! You can also find your order details in the
          Order History.</span
        >
      </div>
      <div class="order">
        <template v-for="mealPeriod in orderData">
          <div
            :key="mealPeriod.mealPeriodId"
            style="display: flex; flex-flow: column"
          >
            <span class="text word-break"
              >{{ filterText("Order") }} #:&nbsp;{{ mealPeriod.orderId }}</span
            >
            <span class="text word-break"
              >{{ filterText("Meal") }} Type:&nbsp;{{
                mealPeriod.mealPeriod
              }}</span
            >
            <span class="text word-break"
              >{{ filterText("Order") }} Time:&nbsp;Today
              {{ moment(orderTime).format("h:mm A") }}</span
            >
            <span
              class="text word-break"
              v-if="mealPeriod.roomNum !== 'Parking'"
              >Requested Time:&nbsp;{{ mealPeriod.time }}</span
            >

            <template v-if="mealPeriod.chargeRoom">
              <span class="text word-break"
                >Room #:&nbsp;{{ mealPeriod.chargeRoom }}</span
              >
              <span class="text word-break"
                >Table #:&nbsp;{{ mealPeriod.roomNum }}</span
              >
            </template>
            <span v-else class="text word-break"
              >Room #:&nbsp;{{ mealPeriod.roomNum }}</span
            >

            <span class="text word-break"
              >Last Name:&nbsp;{{ mealPeriod.lastName }}</span
            >
            <span
              v-if="mealPeriod.roomNum !== 'Parking'"
              class="text word-break"
              style="margin-bottom: 10pt"
              >Number Of Guests:&nbsp;{{ mealPeriod.guestNum }}</span
            >

            <span
              v-if="mealPeriod.roomNum === 'Parking'"
              class="text word-break"
              >Make/Color:&nbsp;{{ mealPeriod.make }}</span
            >

            <span
              v-if="mealPeriod.roomNum === 'Parking'"
              class="text word-break"
              style="margin-bottom: 10pt"
              >License Plate:&nbsp;{{ mealPeriod.license }}</span
            >
          </div>
        </template>
      </div>
      <van-button
        class="btn"
        style="height: 30px"
        size="large"
        @click="
          thankShow = false;
          $emit('confirm');
        "
        >OK</van-button
      >
    </div>
  </van-popup>
</template>

<script>
import moment from "moment-timezone";
export default {
  data() {
    return {
      thankShow: false,
      orderData: [],
      orderTime: "",
    };
  },
  methods: {
    moment,
    init(orderData, orderTime) {
      this.orderData = orderData;
      this.orderTime = orderTime;
      this.thankShow = true;
    },
    filterText(text) {
      if (this.orderData.length && this.orderData[0].roomNum === "Parking") {
        return "Ticket";
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  width: 250pt;
  // height: 150pt;
  text-align: left;
  padding: 10px;

  .title {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14pt;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2b2e36;
    }
  }

  .info {
    margin-top: 10pt;
    font-size: 10pt;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: normal;
    color: #535353;
    padding: 0 10pt;
    // color: #979797;
  }

  .order {
    margin: 10pt 0 5pt 0;
    padding: 10pt;
    border: 1px #535353 dashed;

    .text {
      font-size: 11pt;
      line-height: 15pt;
    }
  }
}
.word-break {
  word-break: normal;
}
.btn {
  background-color: $primary-color;
  border-color: $primary-color;
  color: #fff;
}
</style>
